import { $win } from '../app/globals';

const initBlock = ( $header ) => {
	/**
	 * State variables.
	 */
	let scroll = 0;

	/**
	 * Determines if the header should slide up based on scroll position and menu state.
	 *
	 * @param {number} newScroll - The current scroll position.
	 * @param {number} previousScroll - The previous scroll position.
	 * @param {boolean} subMenuIsOpen - Whether a submenu is currently open.
	 * @returns {boolean} Whether the header should slide up.
	 */
	const shouldHeaderSlideUp = ( newScroll, previousScroll, subMenuIsOpen ) => {
		const isScrollingDown = newScroll > previousScroll;
		const hasScrolledPastThreshold = newScroll > 10;
		const hasScrolledPastHeader = newScroll > $header.outerHeight();

		return !subMenuIsOpen && isScrollingDown && hasScrolledPastThreshold && hasScrolledPastHeader;
	};

	/**
	 * Handle scroll.
	 */
	$win.on( 'scroll resize orientationchange', () => {
		const newScroll = $win.scrollTop();
		const subMenuIsOpen = $( '.menu-item-has-children.is-open' ).length > 0;

		const shouldSlideUp = shouldHeaderSlideUp( newScroll, scroll, subMenuIsOpen );

		$header.toggleClass( 'header--slide-up', shouldSlideUp );
		$header.toggleClass( 'is-sticky', newScroll > 50 );

		scroll = newScroll;
	} );

	/**
	 * Handle hamburger click.
	 */
	$( '.js-header-trigger' ).on( 'click', function( event ) {
		event.preventDefault();

		$( this ).toggleClass( 'is-active' );
		$header.toggleClass( 'is-active' );
		$( 'html' ).toggleClass( 'is-scroll-locked' );

		$( '.menu-item-has-children' )
			.removeClass( 'is-open' );
	} );

	/**
	 * Handle sub-menu trigger click.
	 */
	$( '.menu-item-has-children > a' ).on( 'click', function( event ) {
		event.preventDefault();

		$( this )
			.parent()
			.toggleClass( 'is-open' );
	} );
};

app.loadBlock( initBlock, 'footer', '.js-header' );
